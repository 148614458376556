import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const PlayerCellRenderer = (props: any) => {
  const navigate = useNavigate()
  return (
    <div
      onClick={() => {
        const username = props.data.username;
        navigate(`/player/${username}`);
      }}
      style={{
        cursor: 'pointer',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Button style={{width: '100%'}}>{props?.data?.playerName}</Button>
    </div>
  );
};

export default PlayerCellRenderer;
