import React, { useEffect, useState, useRef } from 'react';
import { useMutation, gql } from '@apollo/client';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import PlayerCellRenderer from '../PlayerCellRenderer';
import './styles.css';

interface Stat {
  playerName: string;
  username: string;
  rank: number;
  ci: number;
  ckdn: number;
  ckdnPercent: number;
  sk: number;
  _int: number;
  commPercent: number;
  comm: number;
  att: number;
  strkspercent: number;
  strks: number;
  lttpercent: number;
  ltt: number;
  optt: number;
  opttPercent: number;
  team: string;
  ng: number;
  pos: string;
  eng: number;
}

const GET_STATS_MUTATION = gql`
  mutation GetStats($timeframe: String!) {
    getStats(timeframe: $timeframe) {
      stats {
        playerName
        username
        rank
        g
        ci
        ckdn
        ckdnPercent
        sk
        _int
        commPercent
        comm
        att
        strkspercent
        strks
        lttpercent
        ltt
        optt
        opttPercent
        team
        ng
        pos
        eng
      }
    }
  }
`;

const MainStats: React.FC = () => {
  const [tableData, setTableData] = useState<Stat[]>([]);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 750);
  const [timeframe, setTimeframe] = useState('Last 15');
  const gridRef = useRef<any>(null);

  const [getStats, { loading, error, data }] = useMutation(GET_STATS_MUTATION);

  const handleResize = () => {
    const mobile = window.innerWidth < 750;
    setIsMobile(mobile);
    if (gridRef.current) {
      const columnDefs = getColumnDefs(mobile);
      gridRef.current.api.setColumnDefs(columnDefs);
    }
  };

  useEffect(() => {
    getStats({ variables: { timeframe } });
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [getStats, timeframe]);

  useEffect(() => {
    if (data) {
      setTableData(data.getStats.stats);
    }
  }, [data]);

  const getColumnDefs = (isMobile: boolean) => [
    {
      headerName: 'Rank',
      field: 'rank' as keyof Stat,
      pinned: 'left' as 'left',
      sortable: true,
      filter: !isMobile,
      width: isMobile ? 60 : 150,
      headerTooltip: 'Rank in the Crucible Leaderboard',
    },
    {
      headerName: 'Player',
      field: 'playerName' as keyof Stat,
      pinned: 'left' as 'left',
      cellRenderer: PlayerCellRenderer,
      filter: !isMobile,
      width: isMobile ? 100 : 150,
      headerTooltip: 'Players first initial, last name',
    },
    {
      headerName: 'Team',
      field: 'team' as keyof Stat,
      pinned: 'left' as 'left',
      filter: !isMobile,
      width: isMobile ? 80 : 100,
      headerTooltip: 'Players team',
    },
    {
      headerName: 'CI',
      field: 'ci' as keyof Stat,
      pinned: 'left' as 'left',
      filter: !isMobile,
      width: isMobile ? 60 : 90,
      headerTooltip: 'Players Crucible Intellect Score',
    },
    { headerName: 'Games', field: 'g' as keyof Stat, filter: !isMobile },
    { headerName: 'Att', field: 'att' as keyof Stat, filter: !isMobile },
    { headerName: 'Comm', field: 'comm' as keyof Stat, filter: !isMobile },
    {
      headerName: 'Comm%',
      field: 'commPercent' as keyof Stat,
      valueFormatter: (params: any) => `${params.value}%`,
      filter: !isMobile,
    },
    { headerName: 'Int', field: '_int' as keyof Stat, filter: !isMobile },
    { headerName: 'Sk', field: 'sk' as keyof Stat, filter: !isMobile },
    { headerName: 'OptT', field: 'optt' as keyof Stat, filter: !isMobile },
    {
      headerName: 'OptT%',
      field: 'opttPercent' as keyof Stat,
      valueFormatter: (params: any) => `${params.value}%`,
      filter: !isMobile,
    },
    { headerName: 'LtT', field: 'ltt' as keyof Stat, filter: !isMobile },
    {
      headerName: 'LtT%',
      field: 'lttpercent' as keyof Stat,
      valueFormatter: (params: any) => `${params.value}%`,
      filter: !isMobile,
    },
    { headerName: 'CkDn', field: 'ckdn' as keyof Stat, filter: !isMobile },
    {
      headerName: 'CkDn%',
      field: 'ckdnPercent' as keyof Stat,
      valueFormatter: (params: any) => `${params.value}%`,
      filter: !isMobile,
    },
    { headerName: 'Strks', field: 'strks' as keyof Stat, filter: !isMobile },
    {
      headerName: 'Strks%',
      field: 'strkspercent' as keyof Stat,
      valueFormatter: (params: any) => `${params.value}%`,
      filter: !isMobile,
    },
  ];

  if (loading)
    return (
      <div style={{ height: 'calc(100vh - 80px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress style={{ color: '#1A283A' }} size={'100px'} />
      </div>
    );
  if (error) return <Typography color="error">Error: {error.message}</Typography>;

  return (
    <div className="main-stats">
      <div className="ag-theme-alpine custom-ag-grid" style={{ height: 'calc(100vh - 80px)', width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          className={`ag-theme-alpine${isMobile ? ' mobile_grid' : ''}`}
          columnDefs={getColumnDefs(isMobile)}
          rowData={tableData}
          defaultColDef={{
            flex: 1,
            minWidth: isMobile ? 60 : 120, // Dynamic minWidth based on parent width
            sortable: true,
            resizable: true,
          }}
          tooltipShowDelay={0}
          suppressBrowserResizeObserver={true}
        />
      </div>
    </div>
  );
};

export default MainStats;
