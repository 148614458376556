import React, { ReactNode, useState } from 'react';
import { Tabs, Tab, Box, Typography, Drawer, IconButton, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { Menu, ArrowBack, Refresh } from '@mui/icons-material';
import { ParentSize } from '@visx/responsive';
import './styles.css';

interface PageContainerProps {
  children: ReactNode;
}

const PageContainer: React.FC<PageContainerProps> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleChange = (event: any, newValue: any) => {
    setActiveTab(newValue);
  };

  const tabLabels = ['Main Stats', 'Team Summary', 'Defensive Stats', 'Offensive Stats'];

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <ParentSize>
      {({ width }) => {
        const isMobile = width < 750;

        return (
          <div>
            <div className="top_page_banner">
              {isMobile && (
                <IconButton onClick={handleDrawerToggle} style={{ color: 'white', marginLeft: 10 }}>
                  <Menu />
                </IconButton>
              )}
              <img
                onClick={() => navigate('/')}
                src={`${process.env.PUBLIC_URL}/logo_white.svg`}
                alt="Logo"
                className="main_logo"
              />
              {location.pathname !== '/' ? (
                 isMobile ? <IconButton
                  style={{ top: '2px', right: '20px' }}
                  color="info"
                  onClick={() => navigate('/')}
                >
                  <ArrowBack style={{color: 'white'}}/>
                </IconButton>
                :<Button
                  className="back-button"
                  variant="outlined"
                  color="info"
                  onClick={() => navigate('/')}
                  startIcon={<ArrowBack style={{ position: 'relative', top: '-2px' }} />}
                >
                  Back To All Players
                </Button>
              ) : isMobile ? (
                <IconButton
                  style={{ top: '2px', right: '4px' }}
                  color="info"
                  onClick={() => window.location.reload()}
                >
                  <Refresh />
                </IconButton>
              ) : (
                <Button
                  className="back-button"
                  variant="outlined"
                  color="info"
                  onClick={() => window.location.reload()}
                  startIcon={<Refresh style={{ position: 'relative', top: '-2px' }} />}
                >
                  {width < 750 ? '' : 'Refresh Stats'}
                </Button>
              )}
            </div>
            <div style={{ paddingTop: '80px' }}>
              <div className="main_page_container">
                {!isMobile && (
                  <div className="side_panel">
                    <Tabs
                      orientation="vertical"
                      value={activeTab}
                      onChange={handleChange}
                      TabIndicatorProps={{ style: { display: 'none' } }}
                      sx={{
                        '& .MuiTab-root': {
                          color: 'white',
                          fontSize: '24px',
                          textTransform: 'none',
                        },
                        '& .Mui-selected': {
                          backgroundColor: 'red',
                          color: 'white',
                        },
                        '& .Mui-disabled': {
                          color: 'grey',
                        },
                      }}
                    >
                      {tabLabels.map((label, index) => (
                        <Tab key={index} label={label} disabled={index > 1} />
                      ))}
                    </Tabs>
                  </div>
                )}
                {children}
              </div>
            </div>
            <Drawer anchor="left" open={isDrawerOpen} onClose={handleDrawerToggle}>
              <Box
                sx={{
                  width: 280,
                  backgroundColor: '#1A283A',
                  height: '100%',
                  padding: '20px 0',
                }}
                role="presentation"
              >
                <Tabs
                  orientation="vertical"
                  value={activeTab}
                  onChange={handleChange}
                  className='drawer-tabs'
                  TabIndicatorProps={{ style: { display: 'none' } }}
                  sx={{
                    '& .MuiTab-root': {
                      color: 'white',
                      fontSize: '24px',
                      textTransform: 'none',
                    },
                    '& .Mui-selected': {
                      backgroundColor: 'red',
                      color: 'white',
                    },
                    '& .Mui-disabled': {
                      color: 'grey',
                    },
                  }}
                >
                  {tabLabels.map((label, index) => (
                    <Tab key={index} label={label} disabled={index > 1} />
                  ))}
                </Tabs>
              </Box>
            </Drawer>
          </div>
        );
      }}
    </ParentSize>
  );
};

export default PageContainer;
