import React, { useEffect, useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './styles.css';
import { AccountBoxTwoTone } from '@mui/icons-material';
import { FormControl, InputLabel, MenuItem, Select, Tabs, Tab, Box } from '@mui/material';
import { cloneDeep } from '@apollo/client/utilities';
import { ParentSize } from '@visx/responsive';

const GET_STATS_BY_USERNAME = gql`
  mutation GetStatsByUsername($username: String!) {
    getStatsByUsername(username: $username) {
      playerName
      rank
      bestOffense {
        offenseName
        playId
        ckdn
        ckdnPercent
        sk
        _int
        commPercent
        comm
        att
        lttpercent
        ltt
        optt
        opttPercent
        g
        ci
      }
      worstOffense {
        offenseName
        playId
        ckdn
        ckdnPercent
        sk
        _int
        commPercent
        comm
        att
        lttpercent
        ltt
        optt
        opttPercent
        g
        ci
      }
      bestCoverage {
        coverageName
        coverageTypeId
        ckdn
        ckdnPercent
        sk
        _int
        commPercent
        comm
        att
        lttpercent
        ltt
        optt
        opttPercent
        g
        ci
      }
      worstCoverage {
        coverageName
        coverageTypeId
        ckdn
        ckdnPercent
        sk
        _int
        commPercent
        comm
        att
        lttpercent
        ltt
        optt
        opttPercent
        g
        ci
      }
      offense {
        offenseName
        playId
        ckdn
        ckdnPercent
        sk
        _int
        commPercent
        comm
        att
        lttpercent
        ltt
        optt
        opttPercent
        g
        ci
      }
      coverage {
        coverageName
        coverageTypeId
        ckdn
        ckdnPercent
        sk
        _int
        commPercent
        comm
        att
        lttpercent
        ltt
        optt
        opttPercent
        g
        ci
      }
      variants {
        offenseName
        coverageName
        playId
        coverageTypeId
        playVersion
        variantName
        ckdn
        ckdnPercent
        sk
        _int
        commPercent
        comm
        att
        lttpercent
        ltt
        optt
        opttPercent
        g
        ci
      }
      ci
      ckdn
      ckdnPercent
      sk
      _int
      commPercent
      comm
      att
      strkspercent
      strks
      g
      lttpercent
      ltt
      optt
      opttPercent
      team
      ng
      pos
      eng
    }
  }
`;

interface PlayerProps {
  username: string;
}

const PlayerPage: React.FC<PlayerProps> = ({ username }) => {
  const difficulties = ['Youth', 'High School', 'College', 'Professional'];
  const [tabIndex, setTabIndex] = useState(0);

  const [getStatsByUsername, { loading, data, error }] = useMutation(GET_STATS_BY_USERNAME);
  const [difficulty, setDifficulty] = useState(difficulties[0]);

  useEffect(() => {
    getStatsByUsername({ variables: { username } });
  }, [getStatsByUsername, username]);

  if (loading) return <div style={{ height: 'calc(100vh - 80px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><CircularProgress style={{ color: '#1A283A' }} size={'100px'} /></div>;

  if (error) return <Typography color="error">Error: {error.message}</Typography>;

  const playerStats = data?.getStatsByUsername;
  console.log(playerStats)

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  return playerStats ? (
    <div className="player-page">
      <ParentSize>
        {({ width }) => {
          console.log(width, 'HEHRHERHERHE')
          if(!width) {
            return
          }
          const isMobile = width < 750
          const columnDefs = [
            {
              headerName: 'Offense',
              field: 'offenseName',
              sortable: true,
              filter: isMobile ? false : true,
              pinned: 'left' as 'left',
              width: isMobile ? 90 : 150,
              tooltipValueGetter: () => 'Name of the offense',
              headerTooltip: 'Name of the offense'
            },
            {
              headerName: 'CI',
              field: 'ci',
              sortable: true,
              filter: isMobile ? false : true,
              pinned: 'left' as 'left',
              width: isMobile ? 75 : 125,
              tooltipValueGetter: () => 'Crucible Intellect',
              headerTooltip: 'Crucible Intellect',
              sort: 'desc' as 'desc',
              sortIndex: 0,
            },
            {
              headerName: 'G',
              field: 'g',
              sortable: true,
              filter: isMobile ? false : true,
              tooltipValueGetter: () => 'Number of games played',
              headerTooltip: 'Number of games played'
            },
            {
              headerName: 'Att',
              field: 'att',
              sortable: true,
              filter: isMobile ? false : true,
              tooltipValueGetter: () => 'Total number of attempts',
              headerTooltip: 'Total number of attempts'
            },
            {
              headerName: 'Comm',
              field: 'comm',
              sortable: true,
              filter: isMobile ? false : true,
              tooltipValueGetter: () => 'Total number of completions',
              headerTooltip: 'Total number of completions'
            },
            {
              headerName: 'Comm%',
              field: 'commPercent',
              sortable: true,
              filter: isMobile ? false : true,
              valueFormatter: (params: any) => `${params.value}%`,
              tooltipValueGetter: () => 'Completion percentage',
              headerTooltip: 'Completion percentage'
            },
            {
              headerName: 'Int',
              field: '_int',
              sortable: true,
              filter: isMobile ? false : true,
              tooltipValueGetter: () => 'Total number of interceptions',
              headerTooltip: 'Total number of interceptions'
            },
            {
              headerName: 'Sk',
              field: 'sk',
              sortable: true,
              filter: isMobile ? false : true,
              tooltipValueGetter: () => 'Total number of sacks',
              headerTooltip: 'Total number of sacks'
            },
            {
              headerName: 'OptT',
              field: 'optt',
              sortable: true,
              filter: isMobile ? false : true,
              tooltipValueGetter: () => 'Total number of optimal throws',
              headerTooltip: 'Total number of optimal throws'
            },
            {
              headerName: 'OptT%',
              field: 'opttPercent',
              sortable: true,
              filter: isMobile ? false : true,
              valueFormatter: (params: any) => `${params.value}%`,
              tooltipValueGetter: () => 'Optimal throw percentage',
              headerTooltip: 'Optimal throw percentage'
            },
            {
              headerName: 'LtT',
              field: 'ltt',
              sortable: true,
              filter: isMobile ? false : true,
              tooltipValueGetter: () => 'Total number of late throws',
              headerTooltip: 'Total number of late throws'
            },
            {
              headerName: 'LtT%',
              field: 'lttpercent',
              sortable: true,
              filter: isMobile ? false : true,
              valueFormatter: (params: any) => `${params.value}%`,
              tooltipValueGetter: () => 'Late throw percentage',
              headerTooltip: 'Late throw percentage'
            },
            {
              headerName: 'CkDn',
              field: 'ckdn',
              sortable: true,
              filter: isMobile ? false : true,
              tooltipValueGetter: () => 'Total number of checkdowns',
              headerTooltip: 'Total number of checkdowns'
            },
            {
              headerName: 'CkDn%',
              field: 'ckdnPercent',
              sortable: true,
              filter: isMobile ? false : true,
              valueFormatter: (params: any) => `${params.value}%`,
              tooltipValueGetter: () => 'Checkdown percentage',
              headerTooltip: 'Checkdown percentage'
            },
          ];
        
          const columnDefs2 = cloneDeep(columnDefs)
          const columnDefs3 = cloneDeep(columnDefs)
          // columnDefs3[0].field = 'variantName';
          //   columnDefs3[0].headerName = 'Variant';
          //   columnDefs3[0].tooltipValueGetter = () => 'Name of the variant';
          //   columnDefs3[0].headerTooltip = 'Name of the variant';
          //remove first item
          columnDefs3.shift();
            const newColumns = [
                {
                    headerName: 'Coverage',
                    field: 'coverageName',
                    sortable: true,
                    filter: isMobile ? false : true,
                    pinned: 'left' as 'left',
                    width: isMobile ? 90 : 150,
                    tooltipValueGetter: () => 'Name of the Coverage',
                    headerTooltip: 'Name of the coverage'
                  },
                  {
                    headerName: 'Offense',
                    field: 'offenseName',
                    sortable: true,
                    filter: isMobile ? false : true,
                    pinned: 'left' as 'left',
                    width: isMobile ? 90: 150,
                    tooltipValueGetter: () => 'Name of the offense',
                    headerTooltip: 'Name of the offense'
                  },
            ]
        
            columnDefs3.splice(0, 0, ...newColumns);
            columnDefs2[0].field = 'coverageName';
            columnDefs2[0].headerName = 'Coverage';
            columnDefs2[0].tooltipValueGetter = () => 'Name of the coverage';
            columnDefs2[0].headerTooltip = 'Name of the coverage';
            
      return(<>
        <div className="player-header">
        <div className="left_container_player">
        <div className="player_name">
            <Typography variant="h4">{playerStats.playerName}</Typography>
            <AccountBoxTwoTone style={{fontSize: '200px'}}/>
        </div>
        <div className="player-info">
            <div className="player-info-item">
                <Typography variant="h5" className="label_player">Team:</Typography>
                <Typography variant="h6">{playerStats.team || '-'}</Typography>
            </div>
            <div className="player-info-item">
                <Typography variant="h5" className="label_player">Email:</Typography>
                <Typography variant="h6">{playerStats.email || '-'}</Typography>
            </div>
            <div className="player-info-item">
                <Typography variant="h5" className="label_player">Phone:</Typography>
                <Typography variant="h6">{playerStats.phone || '-'}</Typography>
            </div>
            </div>
        </div>
        <div style={{paddingTop: '50px'}}>
        <FormControl >
            <InputLabel id="difficulty-label">Difficulty Level</InputLabel>
            <Select
            labelId="difficulty-label"
                label="Difficulty Level"
                style={{width: '150px'}}
                value={difficulty}
                onChange={(event) => setDifficulty(event.target.value)}
            >
            {difficulties?.map((difficulty) => (
                <MenuItem key={difficulty} value={difficulty}>
                {difficulty}
                </MenuItem>
                ))}
            </Select>
        </FormControl>
        </div>
      </div>
      <div className="player-stats">
        {['ci', 'g', 'att', 'comm', 'commPercent', 'sk', '_int', 'ckdn', 'ckdnPercent', 'optt', 'opttPercent', 'ltt', 'lttpercent', 'strks', 'strkspercent'].map((stat, index) => {
          const hasGrey = isMobile ? (index%2 === 0) : (index % (3) === 0 || (index + 1) % 3 === 0)
          console.log(stat, hasGrey)
          return(
            <div 
            key={stat} 
            className={`stat-box ${hasGrey ? 'grey-background' : ''}`}
            >
            <Typography variant="h6">{stat.toUpperCase()?.replace('PERCENT', '%')}</Typography>
            <Typography variant="h5">{playerStats[stat]}{stat.toUpperCase()?.includes('PERCENT') ? '%' : ''}</Typography>
            </div>
        )})}
        </div>
        <div className="player-details">
            <div className="player-details-item">
                <Typography variant="h5" className="label_player_1">Best Offensive Play:</Typography>
                <Typography className="label_player_1_item" variant="h5">{playerStats.bestOffense.offenseName || '-'}</Typography>
            </div>
            <div className="player-details-item">
                <Typography variant="h5" className="label_player_1">Worst Offensive Play:</Typography>
                <Typography className="label_player_1_item" variant="h5">{playerStats.worstOffense.offenseName || '-'}</Typography>
            </div>
            <div className="player-details-item">
                <Typography variant="h5" className="label_player_1">Best Coverage:</Typography>
                <Typography className="label_player_1_item" variant="h5">{playerStats.bestCoverage.coverageName || '-'}</Typography>
            </div>
            <div className="player-details-item">
                <Typography variant="h5" className="label_player_1">Worst Coverage:</Typography>
                <Typography  className="label_player_1_item" variant="h5">{playerStats.worstCoverage.coverageName || '-'}</Typography>
            </div>
        </div>
        <Box sx={{ width: '100%' }}>
          <Tabs value={tabIndex} onChange={handleTabChange} aria-label="player stats tabs" sx={{paddingTop: '0px'}}>
            <Tab label="Offense" />
            <Tab label="Coverage" />
            <Tab label="Variants" />
          </Tabs>
          <TabPanel value={tabIndex} index={0}>
            <div className="offensive-plays-table">
              <div className="ag-theme-alpine custom-ag-grid" style={{ height: '400px', width: '100%' }}>
                <AgGridReact
                  className={isMobile ? 'mobile_grid' : ''}
                  columnDefs={columnDefs}
                  rowData={playerStats.offense}
                  defaultColDef={{
                    flex: 1,
                    minWidth: isMobile ? 60:100,
                    sortable: true,
                    resizable: true,
                  }}
                  suppressBrowserResizeObserver={true}
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <div className="offensive-plays-table">
              <div className="ag-theme-alpine custom-ag-grid" style={{ height: '400px', width: '100%' }}>
                <AgGridReact
                  className={isMobile ? 'mobile_grid' : ''}
                  columnDefs={columnDefs2}
                  rowData={playerStats.coverage}
                  defaultColDef={{
                    flex: 1,
                    minWidth: isMobile ? 60:100,
                    sortable: true,
                    resizable: true,
                  }}
                  suppressBrowserResizeObserver={true}
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel value={tabIndex} index={2}>
            <div className="offensive-plays-table">
              <div className="ag-theme-alpine custom-ag-grid" style={{ height: '400px', width: '100%' }}>
                <AgGridReact
                  className={isMobile ? 'mobile_grid' : ''}
                  columnDefs={columnDefs3}
                  rowData={playerStats.variants}
                  defaultColDef={{
                    flex: 1,
                    minWidth: isMobile ? 60:100,
                    sortable: true,
                    resizable: true,
                  }}
                  suppressBrowserResizeObserver={true}
                />
              </div>
            </div>
          </TabPanel>
        </Box>
        </>
        )}}
      </ParentSize>
    </div>
  ) : (
    <CircularProgress />
  );
};

const TabPanel = (props: { children?: React.ReactNode; value: number; index: number }) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

export default PlayerPage;
