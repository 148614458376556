import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import MainStats from './components/MainStats';
import PageContainer from './components/PageContainer';
import PlayerPage from './components/PlayerPage';
import { ThemeProvider, createTheme } from '@mui/material';

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: 'Jost, Arial, sans-serif',
    },
  });
  return (
    <Router>
         <ThemeProvider theme={theme}>
          <div className="App">
            <PageContainer>
              <Routes>
                <Route path="/" element={<MainStats />} />
                <Route path="/player/:username" element={<PlayerPageWrapper />} />
              </Routes>
            </PageContainer>
          </div>
      </ThemeProvider>
    </Router>
  );
}

// Wrapper component to extract the username from the URL and pass it to PlayerPage
const PlayerPageWrapper = () => {
  const { username } = useParams();
  return <PlayerPage username={username??''} />;
};

export default App;
